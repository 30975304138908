import classes from "./Sidebar.module.css";
import kaptynLogo from "../../assets/logos/kaptyn_sidebar_logo.png";
import React from "react";
import { Link } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import {
  MdOutlineScreenSearchDesktop,
  MdOutlineIntegrationInstructions,
} from "react-icons/md";

function Sidebar() {
  return (
    <React.Fragment>
      <div className={classes.sidebar_root}>
        <div className={classes.sidebar}>
          <h2 className={classes.app_header}>
            <img
              src={kaptynLogo}
              alt="kaptyn logo"
              width="150"
              height=""
              display="flex"
              align-item="centre"
            />
          </h2>
          <ul className={classes.sidebar_menu}>
            <li className={classes.sidebar_menu_option}>
              <button className={classes.button}>
                <IoHomeOutline
                  size={30}
                  style={{
                    color: "white",
                    marginRight: 25,
                    marginTop: 15,
                    marginLeft: 5,
                  }}
                />
                <Link
                  to={"/home"}
                  style={{
                    textDecoration: "none",
                    color: "white",
                    marginTop: 20,
                  }}
                >
                  Home
                </Link>
              </button>

              {/*<button className={classes.button}>Home</button>*/}
            </li>
            <li className={classes.sidebar_menu_option}>
              <button className={classes.button}>
                <MdOutlineScreenSearchDesktop
                  size={30}
                  style={{
                    color: "white",
                    justifyContent: "center",
                    marginTop: 15,
                    marginRight: 25,
                    marginLeft: 5,
                  }}
                />
                <Link
                  to={"/monitoring"}
                  style={{
                    textDecoration: "none",
                    color: "white",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  Monitoring
                </Link>
              </button>
              {/* <button className={classes.button}>Monitoring</button>*/}
            </li>
            <li className={classes.sidebar_menu_option}>
              <button className={classes.button}>
                <MdOutlineIntegrationInstructions
                  size={30}
                  style={{
                    color: "white",
                    justifyContent: "center",
                    marginTop: 15,
                    marginRight: 25,
                    marginLeft: 5,
                  }}
                />
                <Link
                  to={"/dev"}
                  style={{
                    textDecoration: "none",
                    color: "white",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  Dev
                </Link>
              </button>
              {/* <button className={classes.button}>Monitoring</button>*/}
            </li>
            <li className={classes.sidebar_menu_option}>
              <button className={classes.button}>
                <MdOutlineIntegrationInstructions
                  size={30}
                  style={{
                    color: "white",
                    justifyContent: "center",
                    marginTop: 15,
                    marginRight: 25,
                    marginLeft: 5,
                  }}
                />
                <Link
                  to={"/staging"}
                  style={{
                    textDecoration: "none",
                    color: "white",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  Staging
                </Link>
              </button>
              {/* <button className={classes.button}>Monitoring</button>*/}
            </li>
            <li className={classes.sidebar_menu_option}>
              <button className={classes.button}>
                <MdOutlineIntegrationInstructions
                  size={30}
                  style={{
                    color: "white",
                    justifyContent: "center",
                    marginTop: 15,
                    marginRight: 25,
                    marginLeft: 5,
                  }}
                />
                <Link
                  to={"/integration"}
                  style={{
                    textDecoration: "none",
                    color: "white",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  Integration
                </Link>
              </button>
              {/* <button className={classes.button}>Monitoring</button>*/}
            </li>
            <li className={classes.sidebar_menu_option}>
              <button className={classes.button}>
                <MdOutlineIntegrationInstructions
                  size={30}
                  style={{
                    color: "white",
                    justifyContent: "center",
                    marginTop: 15,
                    marginRight: 25,
                    marginLeft: 5,
                  }}
                />
                <Link
                  to={"/production"}
                  style={{
                    textDecoration: "none",
                    color: "white",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  Production
                </Link>
              </button>
              {/* <button className={classes.button}>Monitoring</button>*/}
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Sidebar;
