import { envColumn } from "../../constants/columnTemplate";
import { recordGenerator } from "../../utils/recordGenerator";
import MainContent from "../MainContent/MainContent";
let rows = recordGenerator("prod");

export default function Production() {
  return (
    <MainContent
      rows={rows}
      columns={envColumn}
      title="Production Environment"
    />
  );
}
