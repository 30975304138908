import { envColumn } from "../../constants/columnTemplate";
import { recordGenerator } from "../../utils/recordGenerator";
import MainContent from "../MainContent/MainContent";
let rows = recordGenerator("int");

export default function Integration() {

  return (
    <MainContent
      rows={rows}
      columns={envColumn}
      title="Integration Environment"
    />
  );
}
