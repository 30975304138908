import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import classes from "./MainContent.module.css";

function MainContent(props) {
  const [platform, setPlatform] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const incomingRows = props.rows;
  useEffect(() => {
    let platformList = incomingRows;

    setPlatform(platformList);
    setRows(platformList);
  }, [incomingRows]);

  const columns = props.columns;

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }
  const requestSearch = (searchValue) => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = platform.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  return (
    <div className={classes.card}>
      <div className={classes.dataGrid}>
        <h1 style={{ paddingLeft: "10px" }}>{props.title}</h1>
        <div
          style={{
            height: 400,
            width: props.columns.length === 2 ? 610 : "416%",
          }}
        >
          <Box>
            <TextField
              variant="standard"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                requestSearch(e.target.value);
              }}
              placeholder="Search..."
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" color="action" />,
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{
                      visibility: searchText ? "visible" : "hidden",
                      borderRadius: "57%",
                      paddingRight: "1px",
                      margin: "0",
                      fontSize: "1.25rem",
                    }}
                    onClick={() => {
                      setSearchText("");
                      setRows(platform);
                    }}
                  >
                    <ClearIcon fontSize="small" color="action" />
                  </IconButton>
                ),
              }}
              sx={{
                width: { xs: 1, sm: "auto" },
                m: (theme) => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 1,
                  borderColor: "divider",
                },
              }}
            />
          </Box>
          <DataGrid
            sx={{width:"100%",
            "& .MuiDataGrid-columnHeaders":{
              backgroundColor: "#2ab2db",
            },
              "& .super-app-theme--header": {
                fontWeight: 400,
                fontSize: "18px",
                color: "white",
                backgroundColor: "#2ab2db",
              },
            }}
            disableColumnMenu
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            density="compact"
          />
        </div>
      </div>
    </div>
  );
}
export default MainContent;
