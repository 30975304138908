import classes from "./columnTemplate.module.css";
import { Link } from "@mui/material";

function renderUrl(params) {
  return (
    <Link href={`${params.value}`} target="_blank">
      <div className={classes.cell}>{params.value.toString()}</div>
    </Link>
  );
}

export const toolsColumn = [
  {
    field: "tools",
    headerName: "Tools",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "url",
    headerName: "URL",
    width: 300,
    renderCell: renderUrl,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "description",
    headerName: "Description",
    width: 450,
    headerClassName: "super-app-theme--header",
  },
];

export const envColumn = [
  {
    field: "tools",
    headerName: "Applications",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "url",
    headerName: "URL",
    width: 300,
    renderCell: renderUrl,
    headerClassName: "super-app-theme--header",
  },
];
