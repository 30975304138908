import { envColumn } from "../../constants/columnTemplate";
import { recordGenerator } from "../../utils/recordGenerator";
import MainContent from "../MainContent/MainContent";
let rows = recordGenerator("staging");

export default function Staging() {
  return (
    <MainContent rows={rows} columns={envColumn} title="Staging Environment" />
  );
}
