import MainContent from "../MainContent/MainContent";
import { envColumn } from "../../constants/columnTemplate";
import { recordGenerator } from "../../utils/recordGenerator";
let rows = recordGenerator("dev");

export default function Dev() {
  
  return (
    <MainContent
      rows={rows}
      columns={envColumn}
      title="Development Environment"
    />
  );
}
