import React from "react";
import MonitoringScreen from "../../assets/img/monitoring.png";
import classes from "./Monitoring.module.css";
const Monitoring = () => {
  return (
    <div className={classes.monitor}>
      <img
        src={MonitoringScreen}
        alt="kaptyn logo"
        width="90%"
        height="90%"
        align-items="flex-start"
      />
    </div>
  );
};

export default Monitoring;
