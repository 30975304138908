import React from "react";
import Sidebar from "./components/Sidebar/Sidebar";
import classes from "./App.module.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Monitoring from "./components/Monitoring/Monitoring";
import Dev from "./components/Dev/Dev";
import Staging from "./components/Staging/Staging";
import Production from "./components/Production/Production";
import Integration from "./components/Integration/Integration";
import Home from "./components/Home/Home";

function App() {
  return (
    <div className={classes.main_page}>
      <BrowserRouter>
        <Sidebar />
        <Routes>
          <Route path="/dev" element={<Dev />} />
          <Route path="/staging" element={<Staging />} />
          <Route path="/integration" element={<Integration />} />
          <Route path="/production" element={<Production />} />
          <Route path="/monitoring" element={<Monitoring />} />
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
